.hero {
  padding-top: 30px;
  position: relative;
  z-index: 2;
  overflow-x: clip;
  height: 100%;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
  
}

.hero__inner {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include media("<=1080px") {
    
  }
  @include media("<=900px") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }
  @include media("<=430px") {
    
  }
  @include media("<=375px") {
  }
  @include media("<=320px") {
  }
}

.hero__content {
  flex: 0 1 100%;
  max-width: 840px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include media("<=1280px") {
    flex: 0 1 68%;
    
  }
  @include media("<=900px") {
    align-items: center;
    
  }
    @include media("<=430px") {
      
    }
}

.hero__title {
  font-size: 48px;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: #ffffff;
  font-weight: 300;
  letter-spacing: -2px;
  text-align: center;
  width: 100%;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=1280px") {
    font-size: 40px;
  }

  @include media("<=1080px") {
    font-size: 36px;
  }
  @include media("<=900px") {
    text-align: center;
    margin-bottom: 20px;
    font-size: 40px;
    color: #000;
    max-width: 600px;
  }
  @include media("<=450px") {
    font-size: 28px;
  }

  @include media("<=320px") {
  }
  img {
    width: 100%;
    display: block;
   
  }
  span {
    font-size: 1.4em;
    font-weight: 500;
    display: block;
  }
}



.wrapper {
  display: flex;
  @include media("<=900px") {
    flex-direction: column;
    align-items: center;
    padding-bottom: 390px;
  }
}

.navigation {

  padding: 30px 0;
  position: relative;
  @include media("<=900px") {
    padding: 15px 5px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 325px;
    width: 85%;
    left: 0;
    box-sizing: border-box;
      border: 1px solid rgb(69, 30, 1);
      border-radius: 0 27px 27px 0;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      background:  #67442f;
      z-index: 0;
    @include media("<=900px") {
      background-image: url(../images/mob-bcg.png);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0;
      border: none;
      height: 180%;
      width: 100%;
      top: -75%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.navigation ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 11px;
  position: relative;
  z-index: 3;
  @include media("<=900px") {
    align-items: center;
  }
}

.navigation li {
  cursor: pointer;
  padding: 14px 43px;
  background-color: #fff;
  border-radius: 0 17px 17px 0;
  font-size: 20px;
  font-weight: 800;
  font-style: italic;
  line-height: 1.2;
  color: #4B250E;
  text-shadow: 0 4px 4px #00000049;
  max-width: 348px;
  transition: all 0.3s linear;
  @include media("<=900px") {
    max-width: 500px;
    width: 100%;
    border-radius: 17px;
    text-align: center;
  }
    @include media("<=450px") {
      font-size: 18px;
      padding: 10px 20px;
    }
    &:hover {
      background-color: #c3c3c3;
    }
}

.sidebar {
  max-width: 382px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-image: url(../images/side-bcg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left top;
  @include media("<=1080px") {
    width: 34%;
  }
    @include media("<=900px") {
      position: static;
      max-width: 100%;
      width: 100%;
      background-image: none;
    }
}

.sidewinner {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
  z-index: 3;
  
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 211px;
    height: 332px;
    background-image: url(../images/winner-before.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
  }
    @include media("<=1080px") {
      width: 82%;
    }
    @include media("<=900px") {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
}

.sidewinner__text {
  text-align: center;
  text-transform: uppercase;
  max-width: 238px;
  font-size: 28px;
  font-weight: 500;
  text-shadow: 0 -2px 3px #000000c9;
}

.sidewinner__image {
  max-width: 308px;
  padding: 12px;
  background-image: url(../images/winner-bcg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 3px solid rgba(255, 208, 25, 0.12), ;
  
    border-radius: 33px;
  
  
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  img {
    width: 100%;
    display: block;
  }
}

.sidewinner__title {
  text-align: center;
  font-size: 30px;
  text-shadow: 0 4px 4px #00000040;
  color: #1F0B00;
  letter-spacing: -2px;
  padding-top: 4px;
  @include media("<=1080px") {
      font-size: 26px;
    }
    @include media("<=900px") {
      color: #fff;
      font-size: 30px;
    }
  span {
    display: block;
    color: #8B4300;
    font-size: 14px;
    font-weight: 700;
    @include media("<=900px") {
      color: #fff;
    }
  }
}