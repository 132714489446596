@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@font-face {
    font-family: 'Mont';
    src: url("../fonts/Mont-Regular.eot");
    src: local("☺"), url("../fonts/Mont-Regular.woff") format("woff"),
        url("../fonts/Mont-Regular.ttf") format("truetype"),
        url("../fonts/Mont-Regular.svg") format("svg");
    font-weight: 400;
    font-style: normal;
}



@font-face {
    font-family: 'Mont';
    src: url('../fonts/Mont-Black.eot');
    src: url('../fonts/Mont-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mont-Black.woff') format('woff'),
        url('../fonts/Mont-Black.ttf') format('truetype'),
        url('../fonts/Mont-Black.svg#Mont-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('../fonts/Mont-Heavy.eot');
    src: url('../fonts/Mont-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mont-Heavy.woff') format('woff'),
        url('../fonts/Mont-Heavy.ttf') format('truetype'),
        url('../fonts/Mont-Heavy.svg#Mont-Heavy') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Mont';
    src: url('../fonts/Mont-SemiBold.eot');
    src: url('../fonts/Mont-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mont-SemiBold.woff') format('woff'),
        url('../fonts/Mont-SemiBold.ttf') format('truetype'),
        url('../fonts/Mont-SemiBold.svg#Mont-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('../fonts/Mont-Bold.eot');
    src: url('../fonts/Mont-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mont-Bold.woff') format('woff'),
        url('../fonts/Mont-Bold.ttf') format('truetype'),
        url('../fonts/Mont-Bold.svg#Mont-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('../fonts/Mont-Light.eot');
    src: url('../fonts/Mont-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mont-Light.woff') format('woff'),
        url('../fonts/Mont-Light.ttf') format('truetype'),
        url('../fonts/Mont-Light.svg#Mont-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}