.contact {
    padding: 56px 0 134px;
    position: relative;
    z-index: 3;
    overflow: hidden;
    @include media("<=810px") {
       padding: 50px 0 90px;
    }

    .section__title {
        @include media("<=810px") {
            text-align: center;
        }
    }
}

.contact__inner {
    padding-top: 56px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}



.contact__form {
    max-width: 852px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    @include media("<=810px") {
        max-width: 600px;
        flex: 0 1 100%;
    }

    @include media("<=450px") {
        max-width: 300px;
    }

    button {
        border: none;
        border-style: none;
    }

}

.form__input {
    max-width: 852px;
    width: 100%;
    border-radius: 19px;

    input {
        width: 100%;
        font-size: 20px;
        color: rgb(255, 255, 255);
        padding: 15px;
        border: none;
        border-style: none;
        border-radius: 16px;
        background-image: url(../images/input-bcg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: transparent;

        @include media("<=450px") {
            padding: 10px;
            font-size: 16px;
            border-radius: 8px;
        }
    }
    textarea {
        resize: none;
        width: 100%;
        font-size: 20px;
        color: rgb(255, 255, 255);
        padding: 15px;
        border: none;
        border-style: none;
        border-radius: 16px;
        background-image: url(../images/textarea-bcg.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        background-color: transparent;
        @include media("<=450px") {
                padding: 10px;
                font-size: 16px;
                border-radius: 8px;
            }
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 852px;
    margin: 0 auto;
    color: rgb(51, 51, 51);
    padding: 15px;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    border-radius: 13px;
    cursor: pointer;
    background: linear-gradient(180.00deg, rgb(240, 171, 107), rgb(255, 179, 109) 5%, rgb(139, 67, 0) 30%, rgb(139, 67, 0) 75%,
        rgb(255, 179, 109) 100%);
    background-size: 100% 300%;
    transition: all 0.3s ease-in-out;
    @include media("<=450px") {
            padding: 10px;
            font-size: 16px;
            border-radius: 8px;
        }
    &:hover {
        background-position: 0 100%;
    }
}
