.header {
  width: 100%;
  padding: 67px 30px;
  position: relative;
  @include media("<=1440px") {
    padding: 67px 15px;
  }
  @include media("<=900px") {
    padding: 15px 0;
    background-image: url(../images/mob-bcg.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 5;
  }
  @include media("<=430px") {
    padding: 15px 0 ;
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 90%;
    height: 397px;
    background-image: url(../images/header-bcg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media("<=900px") {
      display: none;
    }
  }
}

.header__inner {
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 20;
  column-gap: 15px;
  @include media("<=900px") {
    justify-content: space-between;
  }
}

.header__nav {
  flex: 0 1 74%;
  width: 74%;
//  max-width: 873px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  @include media("<=1320px") {
  flex: 0 1 68%;
    width: 68%;
    
  }
  @include media("<=900px") {
    display: none;
    max-width: 100%;
    &.active {
      display: flex;
      position: fixed;
      height: 50%;
      width: 100%;
      max-width: none;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-image: url(../images/mob-bcg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      z-index: 20;
      margin-right: 0;
      margin-left: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: space-between;
      align-items: center;
      opacity: 1;
      border-radius: 0 0 30px 30px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      @include media("<=430px") {
        padding-bottom: 0;
        
      }
      .header__link {
        padding: 20px;
        @include media("<=430px") {
          padding: 15px;
        }
        @include media("<=350px") {
          padding: 10px;
        }
      }
    }
  }
}

.header__link {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  color: #FFD97E;
  transition: all 0.3s ease;
  position: relative;
  @include media("<=1320px") {
    font-size: 12px;
  }
    @include media("<=1080px") {
      font-size: 10px;
    }
  @include media("<=900px") {
    font-size: 16px;
    color: #000;
  }

  &:hover {
    color: #ffe6ad;
  }
  &-active {
    color: #ffffff;
  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1em;
  background: #FFD97E;
  bottom: -6px;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}



.logo {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  max-width: 382px;
  width: 100%;
  flex: 0 1 100%;
  @include media("<=1080px") {
    width: 34%;
  }
  @include media("<=900px") {
    position: relative;
    max-width: 244px;
  }
    @include media("<=430px") {
      max-width: 120px;
    }
  img {
    width: 100%;
    display: block;
  }
}

