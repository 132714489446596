.footer {
  background-image: url(../images/footer-bcg.png);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 38px 0;
  @include media("<=900px") {
   background-image: none;
  }
  
}

.footer__inner {
  width: 90%;
  padding: 0 15px;
  margin: 0 auto;
  column-gap: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media("<=1280px") {
    width: 100%;
    justify-content: space-around;
    row-gap: 20px;
  }
  @include media("<=950px") {
    flex-wrap: wrap;
    justify-content: space-around;
    column-gap: 30px;
  }
  @include media("<=600px") {
    flex-direction: column;
  }
}



.footer__title {
  font-size: 32px;
  font-weight: 700;
@include media("<=1080px") {
  font-size: 26px;
}
  @include media("<=950px") {
    flex: 0 1 100%;
    text-align: center;
  }
  @include media("<=430px") {
  }
@include media("<=350px") {
  font-size: 16px;
}
}

.footer__link {
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  column-gap: 14px;
  row-gap: 6px;
  align-items: center;
  padding: 2px 0;
  @include media("<=1280px") {
    column-gap: 10px;
    font-size: 18px;
  }
  @include media("<=430px") {
    font-size: 16px;
    column-gap: 15px;
  }
@include media("<=350px") {
  font-size: 14px;
  justify-content: center;
}
  &:hover {
    text-decoration: underline;
  }
}

.footer__phone {
  display: flex;
    column-gap: 14px;
    row-gap: 6px;
    align-items: center;
}

.footer__icon {
  max-width: 45px;
  width: 100%;
  @include media("<=1080px") {
   max-width: 30px;
  }
}


