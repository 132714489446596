.about {
  position: relative;
  padding: 100px 0 80px;
  overflow-x: clip;
  @include media("<=810px") {
    padding-top: 60px;
  }
  @include media("<=430px") {
    padding: 40px 0 50px;
  }
    .button {
      @include media("<=810px") {
        position: absolute;
        bottom: 0;
      }
    }
}

.about__subtitle {
  text-align: center;
  padding: 20px 0;
  @include media("<=450px") {
    font-size: 14px;
  }
}

.about__inner {
  
  position: relative;
  display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 15px;
    row-gap: 23px;
    padding-top: 36px;
  width: 100%;
  z-index: 2;
  @include media("<=900px") {
    justify-content: space-around;
  }
  @include media("<=550px") {
    flex-direction: column;
    align-items: center;
  }
}

.about__item {
  display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    flex: 0 1 32%;
    max-width: 348px;
    width: 100%;
    min-height: 160px;
    border-radius: 10px;
    padding: 20px;
    background-image: url(../images/features-bcg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    transition: all 0.8s ease 0.2s;
}

.about__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 76px;
  width: 100%;
}

.about__content {
  max-width: 211px;
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  @include media("<=1080px") {
    max-width: 430px;
  }
    @include media("<=810px") {
      max-width: 550px;
    }
}

.about__title {
  font-size: 15px;
    font-weight: 500;
}

.about__text {
  font-size: 12px;
    font-weight: 300;
    transition: all 0.8s ease 0.2s;
  
    
  @include media("<=1080px") {
    font-size: 18px;
  }
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
    font-size: 18px;
    
  }
  
}




