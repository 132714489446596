.features {
  padding-top: 20px;
  padding-bottom: 43px;
  overflow: hidden;
  position: relative;
  @include media("<=810px") {
    padding: 60px 0;
  }
    @include media("<=450px") {
      padding: 40px 0;
    }
  
}

.features__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 15px;
  row-gap: 23px;
  padding-top: 36px;
  @include media("<=900px") {
    justify-content: space-around;
  }
    @include media("<=550px") {
      flex-direction: column;
      align-items: center;
    }
}

.features__item {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  flex: 0 1 32%;
  max-width: 348px;
  width: 100%;
  min-height: 71px;
  border-radius: 10px;
  padding: 20px;
  background-image: url(../images/features-bcg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  transition: all 0.8s ease 0.2s;
  @include media("<=1080px") {
   
  }
  
  @include media("<=900px") {
     min-height: 100px;
     flex: 0 1 48%;
  }
  @include media("<=430px") {
    
  }
}

.features__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 76px;
  width: 100%;
}

.features__content {
  max-width: 211px;
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.features__title {
  font-size: 15px;
  font-weight: 500;
}

.features__text {
  font-size: 12px;
  font-weight: 300;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .card {
    border: 1px solid #ccc;
    margin: 10px;
    padding: 20px;
    width: calc(33.333% - 40px);
    display: flex;
    align-items: center;
    cursor: pointer;

    .card-number {
      font-size: 24px;
      
    }

    .card-content {
      width: 100%;

      .card-title {
        font-size: 20px;
        margin-bottom: 0;
      }

      .card-text {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
      }
    }
  }
}