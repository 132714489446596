.terms {
  padding: 40px 0;
  position: relative;
  z-index: 3;
}

.terms__inner {
  padding-top: 20px;
  font-size: 14px;
  line-height: 1.2;
}

.terms__title {
  font-weight: 700;
  padding-top: 20px;
}

.terms__text {
  padding-top: 20px;
  span {
    font-weight: 800;
  }
  p {
    padding-top: 6px;
  }
  ul {
    padding-top: 6px;
    padding-left: 20px;
    list-style: disc;
  }
}