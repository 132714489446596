body {
  margin: 0;
  font-family: "Spectral SC", serif;
  background: linear-gradient(180.00deg, rgb(0, 0, 0) 8.1%, rgb(31, 11, 0) 52.1%, rgb(62, 29, 9) 74.6%, rgb(107, 61, 39) 100%);
  color: #fff;
  position: relative;
  @include media("<=810px") {

  }
  @include media("<=430px") {

  }
  &.page {
    background-image: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.section__title {
  text-align: center;
  text-transform: uppercase;
  font-size: 48px;
  color: #fff;
  font-weight: 300;
  line-height: 1.1;
  @include media("<=1080px") {
    font-size: 40px;
  }
  @include media("<=900px") {
    font-size: 36px;
    
  }
  @include media("<=430px") {
    font-size: 24px;
  }
  span {
    font-size: 1.4em;
      font-weight: 500;
      display: block;
  }
  p {
    font-style: italic;
  }
}

.section__subtitle {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: rgba(15, 7, 2, 0.85);
  background: rgb(217, 217, 217);
  padding: 22px;
  width: 100%;
  margin-top: 55px;
  @include media("<=430px") {
    padding: 18px;
    font-size: 26px;
  }
}

//BURGER BUTTON//
.burger__button {
  display: none;

  @include media("<=900px") {
    display: block;
    position: fixed;
    top: 45px;
    right: 40px;
    z-index: 200;
  }

  @include media("<=430px") {
    top: 24px;
    right: 20px;
  }

  @include media("<=900px") {
    &.active {
      position: fixed;
      top: 47px;
      right: 40px;
      z-index: 200;
    }
    @include media("<=430px") {
      &.active {
        top: 36px;
        right: 20px;
      }
    }
  }

  .line {
    height: 6px;
    width: 58px;
    margin-bottom: 8px;
    background-color: #000;
    transition: all 200ms ease-in-out;
    opacity: 1;

    @include media("<=736px") {
      height: 4px;
      width: 48px;
    }
  }

  &.active {
    .line {
      background-color: #000;
      &.one {
        transform: rotate(45deg) scale(1.2);
      }
      &.two {
        transform: rotate(-45deg) scale(1.2) translateY(-8px) translateX(8px);
        @include media("<=736px") {
          transform: rotate(-45deg) scale(1.2) translateY(-7px) translateX(7px);
        }
      }
      &.three {
        opacity: 0;
      }
    }
  }
}

//POPUP//
.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
  z-index: 50;
}

.popup.open {
  opacity: 1;
  visibility: visible;

  .popup-confirm {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

.popup-overlay {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}



.popup__close {
  position: absolute;
  max-width: 24px;
  right: 20px;
  top: 20px;

  img {
    width: 100%;
  }

  &-button {
    position: relative;
    top: auto;
    right: auto;
    text-align: center;
    padding: 15px;
    max-width: 207px;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    border: 2px solid #000;
    border-radius: 16px;
  }
}

.popup-confirm {
  width: 100%;
  max-height: 430px;
  height: 100%;
  color: rgba(15, 7, 2, 0.85);
  background: rgb(217, 217, 217);
  margin: auto;
  padding: 79px 72px;
  opacity: 0;
  transition: all 0.3s linear;
  transform: translate(0px, -100%);
  z-index: 60;
  max-width: 497px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media("<=1280px") {}

  @include media("<=1024px") {}

  @include media("<=tablet") {}

  @include media("<=430px") {
    width: 90%;
    max-height: 350px;
    padding: 30px;
  }
}

.popup__title {
  font-size: 48px;
  font-weight: 900;
}

.popup__text {
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
  padding: 35px 0 50px;

  @include media("<=430px") {
    font-size: 18px;
  }
}