.sliders {
    max-width: 834px;
    width: 100%;
    margin-left: auto;
    padding-bottom: 44px;

    @include media("<=1280px") {
        width: 68%;
    }
  @include media("<=900px") {
    width: 100%;
  }
}

.slider-container {
    display: none;
   
}

.slider-container.active {
    display: block;
    
}

.slider {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 57px 0 70px;

    @include media("<=1080px") {
        width: 80%;
        margin: 0 auto;
        overflow: visible;
    }
  @include media("<=900px") {
    width: 90%;
  }

    .slick-list {

        padding: 0 !important;

        @include media("<=1080px") {
            overflow: hidden;
        }

        @include media("<=810px") {}

        @include media("<=430px") {}
    }

    .slick-track {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;

        @include media("<=1080px") {}

        @include media("<=810px") {}

        @include media("<=430px") {}
    }

    .slick-slide {
        position: relative;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid rgba(255, 208, 25, 0.12);
        border-radius: 54px;
        background-image: url(../images/slide-bcg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        max-width: 622px;
        width: 100%;
        height: 340px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s linear;

        @include media("<=600px") {
            flex-direction: column;
            height: auto;
            border: none;
            background-image: none;
        }
    }

    .slider__image {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 240px;
        width: 100%;
        img {
            max-width: 270px;
            display: block;
        }
    }

    .slider__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 16px 0 25px;
        max-width: 352px;
        width: 100%;
        flex: 0 1 100%;
        @include media("<=600px") {
            align-items: center;
            padding: 30px 0 0;
        }
    }

    .slider__title {
        color: rgba(255, 255, 255, 0.75);
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 300;
        margin-bottom: 12px;
        @include media("<=600px") {
            text-align: center;
        }

        span {
            font-weight: 500;
            color: #fff;
            display: block;
            font-size: 20px;
            margin-top: 4px;
        }
    }

    .slider__vol {
        width: fit-content;
        color: #333333;
        font-size: 24px;
        padding: 4px 18px;
        border: 1px solid rgba(255, 208, 25, 0.12), ;
        border-radius: 14px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background: rgba(255, 255, 255, 0.9);

        span {
            font-size: 15px;
        }
    }

    .slider__text {
        flex: 0 1 100%;
        font-size: 14px;
        font-weight: 300;
        @include media("<=600px") {
            margin-bottom: 20px;
        }
    }

    .slick-arrow {
        position: absolute;
        z-index: 10;
        font-size: 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        width: 100%;
        max-width: 31px;
        max-height: 63px;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        cursor: pointer;

        transition: all 0.3s linear;

        &:hover {
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
        }

        @include media("<=810px") {}

        @include media("<=600px") {
            top: 45%;
        }

        @include media("<=375px") {}

        @include media("<=320px") {}

        &.slick-prev {
            left: 0;
            background-image: url(../images/prev.png);

            @include media("<=1080px") {
                left: -36px;
            }

            @include media("<=600px") {
                left: 0;
            }

            @include media("<=430px") {}
        }

        &.slick-next {
            background-image: url(../images/next.png);
            right: 0;

            @include media("<=1080px") {
                right: -36px;
            }

            @include media("<=600px") {
                right: 0;
            }

            @include media("<=430px") {}
        }
    }
}

.slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: 2px;
    transform: translate(-50%, 0);
    z-index: 1000;

    @include media("<=810px") {}

    li {
        list-style: none;
        margin: 0 4px;
    }

    button {
        font-size: 0;
        width: 15px;
        height: 15px;
        background-color: #d9d9d9c7;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        transition: all 0.3s linear;

        @include media("<=430px") {
            width: 12px;
            height: 12px;
        }
    }
}

.slick-dots li.slick-active button {
    background-color: #ffffff;
}

