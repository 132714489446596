.winner {
  padding: 0 0 100px;
  position: relative;
  z-index: 3;
  overflow: hidden;
  @include media("<=810px") {
    padding-bottom: 80px;
  }
    @include media("<=430px") {
      padding-bottom: 60px;
    }
}

.winner__date {
  color: #000;
  padding: 18px 36px;
  border-radius: 19px;
    background: rgb(255, 255, 255);
    max-width: 493px;
    width: 100%;
    margin-left: auto;
    margin-right: -30px;
    @include media("<=500px") {
      padding: 12px 18px;
      font-size: 14px;
    }
}

.winner__inner {
  padding-top: 100px;
  line-height: 1.2;
  @include media("<=810px") {
    padding-top: 80px;
  }
    @include media("<=430px") {
      padding-top: 60px;
    }
}

.winner__title {
  font-weight: 700;
  padding-bottom: 20px;
  font-size: 36px;
  @include media("<=810px") {
    font-size: 28px;
  }
@include media("<=430px") {
  font-size: 24px;
}
}

.winner__text {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 20px;
  text-align: center;
  @include media("<=810px") {
   font-size: 18px;
  }
  span {
    font-weight: 700;
  }
  p {
    padding-top: 10px;
  }
  b {
    font-weight: 700;
  }
}

.winner__sign {
  text-align: right;
  font-size: 20px;
  font-style: italic;
  @include media("<=430px") {
    font-size: 16px;
  }
}